<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4>Buses</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Buses' }">Buses</router-link>
          </li>
          <li class="breadcrumb-item active">Add New</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-md-9">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Add New Bus</h4>
          </div>
          <div class="card-body">
            <!-- FeedBack -->
            <feedback-messages :msgs="msgs" :errors="errors" />
            <!-- /FeedBack -->

            <div class="basic-form">
              <form id="" @submit.prevent="submitFormData()">
                <div class="form-group row">
                  <label class="col-md-3 col-form-label"
                    >Name/Number <span style="color: red">*</span></label
                  >
                  <div class="col-md-9">
                    <input
                      type="text"
                      name="name"
                      required
                      class="form-control"
                      v-model="bus.name"
                      placeholder="Name"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label"
                    >Tracking ID <span style="color: red">*</span></label
                  >
                  <div class="col-md-9">
                    <input
                      type="text"
                      name="bus_tracking_id"
                      required
                      class="form-control"
                      v-model="bus.bus_tracking_id"
                      placeholder="Bus Tracking ID"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label"
                    >Capacity <span style="color: red">*</span></label
                  >
                  <div class="col-md-9">
                    <input
                      type="number"
                      name="capacity"
                      required
                      class="form-control"
                      v-model="capacity"
                      placeholder="Bus Capacity"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label"
                    >Drivers <span style="color: red">*</span></label
                  >
                  <div class="col-md-9">
                    <Select2
                      v-if="lists"
                      v-model="bus.driver_ids"
                      :options="lists.drivers"
                      :name="'driver'"
                      :id="'driver_ids'"
                      :placeholder="'Drivers'"
                      :disabled="false"
                      :required="true"
                      :multiple="true"
                      :settings="{ multiple: true }"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label"
                    >Supervisors <span style="color: red">*</span></label
                  >
                  <div class="col-md-9">
                    <Select2
                      v-if="lists"
                      v-model="bus.supervisor_ids"
                      :options="lists.supervisors"
                      :name="'supervisors'"
                      :id="'supervisor_ids'"
                      :placeholder="'Supervisors'"
                      :disabled="false"
                      :required="true"
                      :multiple="true"
                      :settings="{ multiple: true }"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label"
                    >Students <span style="color: red">*</span></label
                  >
                  <div class="col-md-9">
                    <Select2
                      v-if="lists"
                      v-model="student_ids"
                      :options="lists.students"
                      :name="'students'"
                      :id="'student_ids'"
                      :placeholder="'Students'"
                      :disabled="false"
                      :required="true"
                      :multiple="true"
                      :settings="{ multiple: true }"
                    />
                  </div>
                </div>

                <div
                  class="form-group row"
                  style="padding-top: 2rem; margin-bottom: 0px"
                >
                  <div class="col-md-12">
                    <button
                      :disabled="disableBtn"
                      name="submit"
                      type="submit"
                      class="btn btn-primary"
                      value="Submit"
                      onclick="this.blur();"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeedbackMessages from "@/components/FeedbackMessages.vue";
import usebuses from "@/composables/buses";
import { inject, onBeforeMount, ref, watch } from "vue";
import { useRouter } from "vue-router";
import Select2 from "vue3-select2-component";

export default {
  components: {
    FeedbackMessages,
    Select2,
  },
  setup() {
    const disableBtn = ref(false);
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();
    const bus = ref({
      name: null,
      bus_tracking_id: null,
      capacity: 0,
      driver_ids: [],
      supervisor_ids: [],
      student_ids: [],
    });

    const student_ids = ref("");
    const capacity = ref(0);

    const { storeBus, getLookups, lists, msgs, errors } = usebuses();

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasPermission("add_buses")) {
        router.push({
          name: "401",
        });
      } else {
        getLookups();
      }
    });

    const submitFormData = async () => {
      swal.fire({
        title: "Please wait while submit your request",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      errors.value = [];
      disableBtn.value = true;

      await storeBus(bus).then((resp) => {
        swal.close();
        if (resp != "fail") {
          router.push({
            name: "Buses",
          });
        }
        window.scrollTo(0, 0);
        disableBtn.value = false;
      });
    };

    watch(student_ids, async (newValue, oldValue) => {
      if (newValue.length <= bus.value.capacity) {
        bus.value.student_ids = newValue;
      } else {
        student_ids.value = [];
        if (oldValue && oldValue.length > 0) {
          for (let index = 0; index < oldValue.length; index++) {
            student_ids.value.push(oldValue[index]);
          }
        }
        swal.fire({
          title: "The bus is over capacity?",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    });

    watch(capacity, async (newValue, oldValue) => {
      if (capacity.value < oldValue) student_ids.value = [];
      bus.value.capacity = newValue;
    });

    return {
      submitFormData,
      bus,
      disableBtn,
      lists,
      swal,
      msgs,
      errors,
      student_ids,
      capacity,
    };
  },
};
</script>
